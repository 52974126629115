import React, {useState, useEffect} from 'react';
import {
  Button,
  Modal,
  Grid,
  Icon,
  Form,
  Checkbox,
  Dropdown,
  Message
} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Question} from '../../content/survey/question/question';
import {updateCurrentBook} from '../../../modules/book';
import {updateReaderSignupQuestions} from '../../../modules/survey';
import {authUtil} from '../../../utils';

const JoinBetaFormDesigner = ({
  questions: questionsFromProps,
  readOnly,
  trigger,
  floated,
  size,
  asModal
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentBook = useSelector(state => state.book.currentBook);
  const updatingSignUpSurvey = useSelector(
    state => state.survey.updatingSignUpSurvey
  );
  const signUpSurvey = useSelector(state => state.survey.signUpSurvey);

  const [showModal, setShowModal] = useState(false);
  const [showQuestionsInline, setShowQuestionsInline] = useState(false);
  const [questions, setQuestions] = useState(questionsFromProps);
  const [consentChecked, setConsentChecked] = useState(false);

  useEffect(() => {
    setQuestions(questionsFromProps);
  }, [questionsFromProps]);

  // const getSendRequestElement = () => {
  //   return (
  //     <Form.Group inline widths='equal'>
  //       <Form.Field
  //         control={Checkbox}
  //         toggle
  //         checked={consentChecked}
  //         onChange={(event, data) => {
  //           setConsentChecked(data.checked);
  //         }}
  //         label={t('joinConsentString')}
  //       />
  //       <Form.Field
  //         control={Button}
  //         disabled={!consentChecked}
  //         floated='right'
  //         content={t('SendJoinRequest')}
  //         icon='right chevron'
  //         labelPosition='right'
  //       />
  //     </Form.Group>
  //   );
  // };

  const doAddNewQuestion = () => {
    console.log('addNewQuestion');
    const updatedQuestions = [...questions];
    updatedQuestions.push({
      kind: 'LONG_TEXT',
      options: {},
      question: `${t('Question')} ${questions.length + 1}`
    });
    setQuestions(updatedQuestions);
  };

  const doDeleteQuestion = questionIndex => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(questionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const doUpdateQuestion = newData => {
    let updatedQuestion = questions[newData.index];
    updatedQuestion = {
      ...updatedQuestion,
      ...newData
    };
    const updatedQuestions = [...questions];
    updatedQuestions.splice(newData.index, 1, updatedQuestion);
    setQuestions(updatedQuestions);
  };

  const getQuestionElements = () => {
    const addNewQuestionElement = (
      <Grid.Row key='add-new-question'>
        <Grid.Column>
          <Button
            fluid
            onClick={doAddNewQuestion}
            disabled={readOnly}
            basic
            icon='add'
            content={
              questions.length === 0
                ? t('YouCanAskUpToXQuestions', {maxQuestions: 3})
                : t('NewQuestion')
            }
          />
        </Grid.Column>
      </Grid.Row>
    );

    if (questions && questions.length > 0) {
      const questionList = questions.map((question, index) => (
        <Grid.Row key={`question_${index}`} style={{marginBottom: '1em'}}>
          <Grid.Column width={16}>
            <Question
              t={t}
              index={index}
              question={question}
              deleteQuestion={() => {
                doDeleteQuestion(index);
              }}
              updateQuestion={doUpdateQuestion}
            />
          </Grid.Column>
        </Grid.Row>
      ));
      questionList.unshift(
        <Message
          basic
          size='large'
          content={t('YouCanAskUpToXQuestions', {maxQuestions: 3})}
        />
      );
      return [questionList, questions.length < 3 && addNewQuestionElement];
    }
    return addNewQuestionElement;
  };

  const saveQuestions = async () => {
    const idToken = await authUtil.getFreshIdToken();

    if (currentBook) {
      // create the survey object if it doesn't exist already, then load it with the fresh questions
      let updatedSurvey;
      if (currentBook.readerSignupSurvey) {
        updatedSurvey = {
          ...updatedSurvey,
          questions
        };
      } else {
        updatedSurvey = {
          book: currentBook._id,
          questions
        };
      }
      updatedSurvey.placement = 'READER_SIGNUP';
      const data = {
        surveyData: updatedSurvey,
        bookId: currentBook._id
      };
      dispatch(
        updateReaderSignupQuestions(idToken, data, returnedSurvey => {
          // now save the book with the new or updated survey
          dispatch(
            updateCurrentBook({
              ...currentBook,
              readerSignupSurvey: returnedSurvey
            })
          );
        })
      );
    }
    setShowModal(false);
  };

  const doShowQuestionsInline = () => {
    console.log('doShowQuestionsInline');
    setShowQuestionsInline(!showQuestionsInline);
    if (questions.length === 0) {
      doAddNewQuestion();
    }
  };

  const getTriggerComponent = () => {
    let triggerComponent;
    switch (trigger) {
      case 'Button':
        triggerComponent = (
          <Button
            onClick={() => {
              setShowModal(true);
            }}>
            <Icon name='tasks' />
            {t('ReaderSignUpForm')}
          </Button>
        );
        break;
      case 'Dropdown.Item':
        triggerComponent = (
          <Dropdown.Item
            floated={floated}
            size={size}
            onClick={() => {
              setShowModal(false);
            }}>
            <Icon name='tasks' />
            {t('ReaderSignUpForm')}
          </Dropdown.Item>
        );
        break;
      default:
        triggerComponent = (
          <Button
            onClick={() => {
              setShowModal(true);
            }}>
            <Icon name='tasks' />
            {t('ReaderSignUpForm')}
          </Button>
        );
    }
    return triggerComponent;
  };

  const questionElement = getQuestionElements();
  const triggerComponent = getTriggerComponent();
  if (asModal) {
    return (
      <Modal
        trigger={triggerComponent}
        open={showModal}
        closeIcon
        onClose={() => {
          setShowModal(false);
        }}
        style={{color: 'black'}}>
        <Modal.Header>
          <Icon name='users' />
          {` ${t('JoinBeta')}`}
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row verticalAlign='middle'>
              <Grid.Column width={16}>{questionElement}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Button
                  compact
                  floated='right'
                  disabled={readOnly}
                  loading={updatingSignUpSurvey}
                  onClick={event => {
                    event.preventDefault();
                    saveQuestions();
                  }}
                  style={{float: 'right'}}>
                  {t('Save')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
  return (
    <Grid>
      {showQuestionsInline ? (
        [
          <Grid.Row key='inlineQuestionList' verticalAlign='middle'>
            <Grid.Column width={16}>{questions}</Grid.Column>
          </Grid.Row>,
          ((questions && questions.length > 0) ||
            questionsFromProps.length !== questions.length) && (
            <Grid.Row key='inlineSignUpSurveySaveButtonKey'>
              <Grid.Column width={16}>
                <Button
                  compact
                  floated='right'
                  disabled={readOnly}
                  loading={updatingSignUpSurvey}
                  onClick={event => {
                    event.preventDefault();
                    saveQuestions();
                  }}
                  style={{float: 'right'}}>
                  {t('Save')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          )
        ]
      ) : (
        <Grid.Row key='showSignUpSurveyQuestionsButton' verticalAlign='middle'>
          <Grid.Column width={16}>
            <Button
              fluid
              onClick={() => doShowQuestionsInline()}
              disabled={readOnly}
              basic
              icon={questionsFromProps.length === 0 ? 'add' : 'edit'}
              content={
                questionsFromProps.length === 0
                  ? t('AddSignUpSurveyQuestions')
                  : t('EditSignUpSurveyQuestions')
              }
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default JoinBetaFormDesigner;
