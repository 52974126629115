import accessUtil from './accessUtil';

const readerCapStatuses = [
  'APPROVED',
  'ACCEPTED',
  'ABANDONED',
  'SENT',
  'CREATED'
];

const canAddMoreReaders = ({
  activeAccount = {},
  readers = [],
  activeReaders,
  currentBook = {}
}) => {
  if (
    currentBook.privacy === 'unpublished' ||
    !accessUtil.isAllowed(currentBook.access, ['edit'])
  ) {
    // can't add readers to unpublished books
    return false;
  }
  // can only add readers if the amount doesn't exceed maxReadersPerBook
  if (!activeAccount) {
    // no account to check amount of reader slots on
    return false;
  }
  let {maxReadersPerBook} = activeAccount;
  if (currentBook.maxReadersPerBookOverride !== undefined) {
    // override with book value
    maxReadersPerBook = currentBook.maxReadersPerBookOverride;
  }
  if (maxReadersPerBook === -1) {
    // this account can add as many readers as they please
    return true;
  }
  // this account has a readers per book limit. check if it's been exceeded.
  const totalInvitationsAndAccepted =
    activeReaders ||
    readers.filter(
      reader =>
        readerCapStatuses.includes(reader.invitation.status) &&
        reader.enabled === true &&
        !reader.swap &&
        reader.source !== 'book'
    ).length;
  // return true if the account has not yet exceeded the amount of reader slots for this account
  return maxReadersPerBook > totalInvitationsAndAccepted;
};

const getBookCounts = (writingList = []) => {
  const unpublishedCount = writingList.filter(
    book => book?.privacy === 'unpublished'
  ).length;
  const privateCount = writingList.filter(
    book => book?.privacy === 'private'
  ).length;
  const closedCount = writingList.filter(
    book => book?.privacy === 'closed'
  ).length;
  const listedCount = writingList.filter(
    book => book?.privacy === 'listed'
  ).length;
  const publishedCount = privateCount + closedCount + listedCount;

  return {
    unpublishedCount,
    privateCount,
    closedCount,
    listedCount,
    publishedCount
  };
};

const canEditAccount = ({activeAccount, userProfile}) =>
  activeAccount && activeAccount.owner._id === userProfile._id;

const canInviteAccountMembers = ({userProfile = {}}) => {
  const {account} = userProfile;
  return account && account.product && account.product.internalId !== 'free';
};

const isOnFreePlan = ({userProfile = {}}) => {
  const {account} = userProfile;
  return account?.product?.internalId === 'free';
};

export default {
  canAddMoreReaders,
  getBookCounts,
  canEditAccount,
  canInviteAccountMembers,
  isOnFreePlan
};
