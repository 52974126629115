import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';

// i18n
import { withTranslation } from 'react-i18next';
// redux
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import gaTracker from '../../utils/gaTracker';
import BetaOpportunityItem from '../discover/betaOpportunityItem';

import {
  updateReadingList
} from '../../modules/book';

export class ReadingList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    gaTracker.trackPageView(this.props.match.path);
    const { idToken } = this.props;
    if (idToken) {
      this.props.updateReadingList(idToken);
    }
  }

  componentDidUpdate(prevProps) {
    const { idToken } = this.props;
    // get currently writing list if we just got the idToken
    if (!prevProps.idToken && idToken) {
      updateReadingList(idToken);
    }
  }

  render() {
    const {
      userProfile, reading, t
    } = this.props;
    if (userProfile === undefined) {
      return (<Loader />);
    }
    return (
      <div id='reading-list' style={{ display: 'flex', flexDirection: 'column', padding: '10px 10px 0px 10px' }}>
        {
          reading && reading.length > 0
          && reading.map(book => <BetaOpportunityItem key={book._id} book={book} descriptionMaxLength={500} showLeaveOption />)
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  reading: state.book.reading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateReadingList,
  changePage: newPage => push(newPage)
}, dispatch);

export default withTranslation()(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadingList)));
