import React, {useCallback} from 'react';

// i18n
import {useTranslation, withTranslation} from 'react-i18next';

// Routes
import {Route, Switch, useLocation, withRouter} from 'react-router-dom';

import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import {Menu, Icon, Popup, Modal} from 'semantic-ui-react';

import {loggedIn, getUserProfile, updateUserProfile} from '../../modules/user';

import {saveBook} from '../../modules/book';

import DiscoverSearch from '../discover/discoverSearch';
import Browse from '../discover/browse';

import {toggleBrowseMenu} from '../../modules/app';
import {updateSearchTerm} from '../../modules/betaOpportunities';
import NotificationsMenuItem from '../notifications/notificationsMenuItem';
import UpgradeMenuItem from './upgradeMenuItem';
import UserDropdown from './userDropdown';
import ShareBookMenuItem from './shareBookMenuItem';
import {appUtil, authUtil} from '../../utils';
import EditBookContentContextMenu from './editBookContentContextMenu';
import BackMenuButton from './backMenuButton';
import SidebarToggler from './sidebarToggler';

const menuBackground = '#f1f1f1!important';
const MenuWrapper = styled(Menu)`
  background: ${menuBackground};
  display: flex;
  width: 100%;
  margin: 0px !important;
`;

const HeaderWrapper = styled.header`
  z-index: 1;
  background-color: pink;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  flex-direction: column;
  max-width: 100vw;
`;

export const TitleMenuItemWrapper = styled(Menu.Item)`
  flex: 1 2 300px;
  max-width: 40%;
`;

export const TitleWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RightMenuWrapper = styled(Menu.Menu)`
  /* position: absolute; */
  /* right: 0px; */
  background: ${menuBackground};
`;

const Spacer = styled.div`
  ${({width}) => css`
    width: ${width}px;
  `}
`;

MenuWrapper.propTypes = {
  sidebarVisible: PropTypes.bool
};

const ContextMenu = ({
  fontSizes,
  userProfile,
  currentBook,
  saveButton,
  currentPartTitle,
  searchTerm,
  sidebarVisible,

  updateUserProfile
}) => {
  const location = useLocation();
  const {t} = useTranslation();
  const currentFontSizeIndex = useCallback(() => {
    if (!userProfile?.readerSettings) {
      return 0;
    }
    const currentFontSizeIndex = fontSizes.findIndex(
      size => size.name === userProfile?.readerSettings.fontSize
    );
    return currentFontSizeIndex;
  }, [fontSizes, userProfile?.readerSettings]);

  const updateFontSize = useCallback(
    async fontSize => {
      const idToken = await authUtil.getFreshIdToken();
      updateUserProfile(idToken, {
        readerSettings: {
          ...userProfile.readerSettings,
          fontSize
        }
      });
    },
    [updateUserProfile, userProfile?.readerSettings]
  );

  const increaseFontSize = useCallback(() => {
    const nextFontSizeIndex = currentFontSizeIndex() + 1;
    if (nextFontSizeIndex >= 0) {
      updateFontSize(fontSizes[nextFontSizeIndex].name);
    }
  }, [currentFontSizeIndex, updateFontSize, fontSizes]);

  const decreaseFontSize = useCallback(() => {
    const nextFontSizeIndex = currentFontSizeIndex() - 1;
    if (nextFontSizeIndex < fontSizes.length) {
      updateFontSize(fontSizes[nextFontSizeIndex].name);
    }
  }, [currentFontSizeIndex, updateFontSize, fontSizes]);

  const getUpgradeAndNotificationMenuItems = useCallback(() => {
    if (!userProfile) {
      return null;
    }
    return [
      <ShareBookMenuItem key='share' />,
      <UpgradeMenuItem key='upgrade' />,
      location.pathname !== '/read' && (
        <NotificationsMenuItem key='notifications' fitted link />
      ),
      location?.pathname === '/read' && [
        currentBook && (
          <Modal
            key='feedbackGuidelines'
            trigger={<Menu.Item fitted icon='list alternate outline' />}>
            <Modal.Header>{t('FeedbackGuidelines')}</Modal.Header>
            <Modal.Content>
              <Modal.Description style={{whiteSpace: 'pre-wrap'}}>
                {currentBook.feedbackGuidelines || t('NoGuidelines')}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        ),
        <Popup
          key='font'
          on='click'
          basic
          hoverable
          trigger={<Menu.Item fitted icon='font' />}
          position='bottom left'
          content={
            <div
              style={{
                width: 50,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline'
              }}>
              <Icon
                size='small'
                name='minus'
                link
                onClick={decreaseFontSize}
                disabled={
                  !userProfile ||
                  userProfile.readerSettings.fontSize === 'small'
                }
              />
              <Icon name='font' />
              <Icon
                size='small'
                name='plus'
                link
                onClick={increaseFontSize}
                disabled={
                  !userProfile || userProfile.readerSettings.fontSize === 'big'
                }
              />
            </div>
          }
        />
      ]
    ];
  }, [
    currentBook,
    increaseFontSize,
    decreaseFontSize,
    location.pathname,
    t,
    userProfile
  ]);

  const getCurrentContextMenu = useCallback(() => {
    const {pathname} = location;
    const menuBar = [];
    if (pathname.includes('/exit-survey')) {
      menuBar.push(
        <Menu.Item key='title' name={t('LeaveBeta')} content={t('LeaveBeta')} />
      );
    }
    if (pathname === '/feed') {
      menuBar.push(
        <Menu.Item key='feed' name={t('Feed')} content={t('Feed')} />
      );
    }
    if (pathname.includes('/mymanuscripts')) {
      menuBar.push(
        <Menu.Item
          key='title'
          name={t('MyManuscripts')}
          content={t('MyManuscripts')}
        />
      );
    } else if (pathname.includes('/account')) {
      menuBar.push(
        <Menu.Item key='title' name={t('Account')} content={t('Account')} />
      );
    } else if (pathname.includes('/inbox')) {
      menuBar.push(
        <Menu.Item key='title' name={t('Chat')} content={t('Chat')} />
      );
    } else if (pathname.includes('/manuscript-inbox/submission')) {
      menuBar.push(
        <Menu.Item
          key='title'
          name={t('SubmitManuscript')}
          content={t('SubmitManuscript')}
        />
      );
    } else if (pathname.includes('/manuscript-inbox')) {
      menuBar.push(
        <Menu.Item
          key='title'
          name={t('ManuscriptInboxes')}
          content={t('ManuscriptInboxes')}
        />
      );
    } else if (pathname.includes('/discover')) {
      menuBar.push(
        <Browse key='browse' />,
        <Menu.Item key='search' as={DiscoverSearch} />
      );
    } else if (pathname.includes('/readinglist')) {
      menuBar.push(
        <Menu.Item
          key='title'
          name={t('ReadingList')}
          content={t('ReadingList')}
        />
      );
    } else if (pathname.includes('/notifications')) {
      menuBar.push(
        <Menu.Item
          key='title'
          name={t('Notifications')}
          content={t('Notifications')}
        />
      );
    } else if (pathname.startsWith('/readers')) {
      menuBar.push(<Menu.Item key='title' content={t('ReaderList')} />);
    } else if (pathname === '/read' && currentBook) {
      menuBar.push(
        ...[
          <TitleMenuItemWrapper
            key='title'
            fitted
            header
            content={
              <TitleWrapper>
                <Icon name='book' />
                <span>{currentBook.title}</span>
              </TitleWrapper>
            }
          />
        ]
      );
    } else if (pathname.startsWith('/books') && currentBook) {
      menuBar.push(
        ...[
          <TitleMenuItemWrapper
            key='title'
            fitted
            header
            content={
              <TitleWrapper>
                <Icon name='book' />
                <span>{currentPartTitle || currentBook.title}</span>
              </TitleWrapper>
            }
          />,
          saveButton && (
            <Menu.Item key='save-book' position='right' content={saveButton} />
          )
        ]
      );
    }
    if (!saveButton && !(searchTerm?.length > 0 && appUtil.isSmallScreen())) {
      menuBar.push(
        <RightMenuWrapper key='right-menu' position='right'>
          {getUpgradeAndNotificationMenuItems()}
          <UserDropdown />
        </RightMenuWrapper>
      );
    }
    return menuBar;
  }, [
    location,
    searchTerm,
    saveButton,
    currentBook,
    currentPartTitle,
    getUpgradeAndNotificationMenuItems,
    t
  ]);

  // Show edit content menu
  const inContentMode = location.pathname.includes('/content');

  const getTopMenu = useCallback(
    () => (
      <MenuWrapper
        key='context-menu'
        secondary
        $sidebarVisible={sidebarVisible}>
        <Switch>
          <Route path={'/read'}>
            {/* when reading, the back button should lead to the reading list */}
            <BackMenuButton toPath={'/readinglist'} />
          </Route>
          <Route path={'/books/:bookId/content'}>
            {/* when editing, the back button should lead to the book overview */}
            <BackMenuButton toPath={`/books/${currentBook?._id}/overview`} />
          </Route>
          <Route path={'/books/:bookId'}>
            {/* when in the other book "sub views", the back button should lead to /my-manuscripts
           for editors, and to discover for readers */}
            <BackMenuButton
              toPath={
                ['author', 'collaborator'].includes(currentBook?.role)
                  ? '/mymanuscripts'
                  : '/discover'
              }
            />
          </Route>
          <Route>
            <Spacer width={14} />
          </Route>
        </Switch>
        {inContentMode ? (
          <EditBookContentContextMenu />
        ) : (
          [<SidebarToggler key='sidebar-toggler' />, getCurrentContextMenu()]
        )}
      </MenuWrapper>
    ),
    [getCurrentContextMenu, sidebarVisible, inContentMode, currentBook]
  );

  return <HeaderWrapper>{getTopMenu()}</HeaderWrapper>;
};

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  currentPartTitle: state.book.currentPartTitle,
  currentSpineIndex: state.readerApp.currentSpineIndex,
  pendingBookChanges: state.book.pendingBookChanges,
  pendingPartChanges: state.book.pendingPartChanges,
  saveButton: state.book.saveButton,
  alerts: state.app.alerts,
  percentagePassed: state.readerApp.percentagePassed,
  sidebarVisible: state.app.sidebarVisible,
  fontSizes: state.app.fontSizes,
  searchTerm: state.betaOpportunities.searchTerm
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loggedIn,
      getUserProfile,
      saveBook,
      toggleBrowseMenu,
      updateSearchTerm,
      updateUserProfile,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ContextMenu))
);
