import React, {useEffect, useState, useCallback, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Button, Icon, Loader, Item, Label, Modal, Tab} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Media} from 'src/utils/Media';

import BookItem from '../../components/book/bookItem';
import BookItemPlaceholder from '../../components/book/bookItemPlaceholder';
import ChapterUploadModal from '../content/chapter/upload/chapterUploadModal';
import CreateFirstBookSegment from './createFirstBookSegment';
import ActiveReaders from '../../components/statistics/activeReaders';
import WelcomeMessage from '../../components/standard-messages/welcomeMessage';
import GetMoreReadersMessage from '../../components/standard-messages/getMoreReadersMessage';
import UnlockSignupBonusMessage from '../../components/standard-messages/unlockSignupBonusMessage';
import NewBook from '../book/newBook';

import {updateWritingList, updateCollaborationList} from '../../modules/book';
import {fetchReaderStatistics} from '../../modules/reader';

import privacy from '../../config/privacy';
import {authUtil, productCapUtil, uxAnalyticsUtil} from '../../utils';

const READER_STATS_INTERVAL = 60000;

export const MyManuscripts = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const refreshDashBoardInterval = useRef(0);

  const user = useSelector(state => state.user.user);
  const activeAccount = useSelector(state => state.account.activeAccount);
  const isFetchingCollaborationsList = useSelector(
    state => state.book.isFetchingCollaborationsList
  );
  const isFetchingWritingList = useSelector(
    state => state.book.isFetchingWritingList
  );
  const userProfile = useSelector(state => state.user.userProfile);
  const writing = useSelector(state => state.book.writing);
  const collaborating = useSelector(state => state.book.collaborating);
  const readerStatistics = useSelector(state => state.reader.readerStatistics);

  const [showChapterUpload, setShowChapterUpload] = useState(false);
  const [showModal, setShowModal] = useState<boolean | string>(false);

  useEffect(() => {
    uxAnalyticsUtil.trackPageView(location.pathname);
    const doFetchReaderStatistics = async () => {
      const idToken = await authUtil.getFreshIdToken();
      dispatch(fetchReaderStatistics(idToken));
    };

    doFetchReaderStatistics();
    refreshDashBoardInterval.current = setInterval(
      doFetchReaderStatistics,
      READER_STATS_INTERVAL
    );
    return () => {
      // clear interval when component unmounts
      clearInterval(refreshDashBoardInterval.current);
    };
  }, []);

  useEffect(() => {
    // set up dashboard again when activeAccount changes
    setupDashboard();
  }, [activeAccount]);

  const setupDashboard = async () => {
    const idToken = await authUtil.getFreshIdToken();
    if (idToken) {
      dispatch(
        updateWritingList({
          idToken,
          accountId: activeAccount ? activeAccount._id : userProfile.account._id
        })
      );
      dispatch(
        updateCollaborationList({
          idToken
        })
      );
    }
  };

  const toggleChapterUpload = () => {
    setShowChapterUpload(!showChapterUpload);
    if (!showChapterUpload) {
      uxAnalyticsUtil.trackEvent({
        category: 'Books',
        action: 'clicked-add-book',
        label: 'upload'
      });
    }
  };

  const handleCreateBookClick = () => {
    uxAnalyticsUtil.trackEvent({
      category: 'Books',
      action: 'clicked-add-book',
      label: 'create'
    });
    setShowModal('create-book');
  };

  const renderBooksList = ({
    books = [],
    isLoading = false,
    showUpload = false
  }) => {
    if (isLoading) {
      // Render placeholder if we're still loading
      return [
        <BookItemPlaceholder key='first-placeholder' />,
        <BookItemPlaceholder key='second-placeholder' />,
        <BookItemPlaceholder key='third-placeholder' />
      ];
    }
    if (books?.length > 0) {
      // Render books if any
      const booksList = books?.map(book => {
        const isAuthor = book?.author && book?.author?._id === user.uid;
        return <BookItem key={book?._id} book={book} isAuthor={isAuthor} />;
      });
      return <Item.Group divided>{booksList}</Item.Group>;
    }
    if (showUpload) {
      // Otherwise, render the upload placeholder to incite the user to start testing a manuscript
      const result = [
        <CreateFirstBookSegment
          key='create-first-book'
          onCreateManuscript={handleCreateBookClick}
        />,
        <WelcomeMessage
          key='welcome'
          toggleChapterUpload={toggleChapterUpload}
        />
      ];
      if (userProfile?.wasReferred && !userProfile?.signupReferralBonus) {
        result.push(
          <UnlockSignupBonusMessage
            key='unlock-bonus'
            userProfile={userProfile}
            user={user}
            writing={writing}
          />
        );
      }
      return result;
    }
    return null;
  };

  const hideModal = () => setShowModal(false);

  const getCounts = useCallback(
    () => productCapUtil.getBookCounts(writing),
    [writing]
  );

  const {unpublishedCount, privateCount, closedCount, listedCount} =
    getCounts();

  if (!userProfile) {
    return <Loader />;
  }

  const panes = [
    {
      menuItem: t('OwnBooks'),
      render: () => (
        <Tab.Pane as='div' key='own'>
          <Item.Group link divided>
            {renderBooksList({
              books: writing,
              showUpload: true,
              isLoading: isFetchingWritingList
            })}
          </Item.Group>
        </Tab.Pane>
      )
    },
    {
      menuItem: t('Collaborations'),
      render: () => (
        <Tab.Pane as='div' key='collabs'>
          <Item.Group link divided>
            {renderBooksList({
              books: collaborating,
              showUpload: false,
              isLoading: isFetchingCollaborationsList
            })}
          </Item.Group>
        </Tab.Pane>
      )
    }
  ];

  return (
    <div style={{paddingLeft: 15, paddingRight: 15}}>
      {writing && writing?.length > 0 && (
        <ActiveReaders readerStatistics={readerStatistics} />
      )}
      <div style={{marginBottom: 20}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div id='books-length-label'>
            {unpublishedCount > 0 && (
              <Label
                icon={privacy.privacyIcons.unpublished}
                content={unpublishedCount}
              />
            )}
            {privateCount > 0 && (
              <Label
                icon={privacy.privacyIcons.private}
                content={privateCount}
              />
            )}
            {closedCount > 0 && (
              <Label icon={privacy.privacyIcons.closed} content={closedCount} />
            )}
            {listedCount > 0 && (
              <Label icon={privacy.privacyIcons.listed} content={listedCount} />
            )}
          </div>
          <div style={{flex: 1, alignSelf: 'flex-end'}}>
            <Media greaterThan='mobile'>
              <Button
                icon
                style={{float: 'right'}}
                onClick={toggleChapterUpload}>
                <>
                  <Icon name='upload' />
                  {t('Import')}
                </>
              </Button>
              <Button
                icon
                style={{float: 'right'}}
                onClick={handleCreateBookClick}>
                <>
                  <Icon name='add' />
                  {t('Create')}
                </>
              </Button>
            </Media>
            <Media at='mobile'>
              {(className, renderChildren) =>
                renderChildren && (
                  <Button.Group style={{float: 'right'}}>
                    <Button icon>
                      <Icon name='upload' onClick={toggleChapterUpload} />
                    </Button>
                    <Button icon>
                      <Icon name='add' onClick={handleCreateBookClick} />
                    </Button>
                  </Button.Group>
                )
              }
            </Media>
          </div>
        </div>
      </div>
      <div>
        <Tab menu={{secondary: true, pointing: true}} panes={panes} />
        {userProfile?.account?.product?.internalId === 'free' && [
          <GetMoreReadersMessage
            key='get-more-readers'
            user={user}
            userProfile={userProfile}
          />,
          <Link
            key='check-bonus'
            style={{float: 'right'}}
            to='/account/reader-bonus'>
            <em>{t('CheckBonus')}</em>
          </Link>
        ]}
      </div>
      {showChapterUpload && (
        <ChapterUploadModal
          createBook
          open
          onClose={toggleChapterUpload}
          toggleOpen={toggleChapterUpload}
        />
      )}
      {showModal === 'create-book' && (
        <Modal open closeIcon onClose={hideModal}>
          <Modal.Content>
            <NewBook />
          </Modal.Content>
        </Modal>
      )}
    </div>
  );
};

export default MyManuscripts;
