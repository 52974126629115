import React from 'react';
import {connect} from 'react-redux';
import {Route, Redirect, withRouter} from 'react-router-dom';
import {Loader, Message} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const RestrictedBookRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated) {
        // no book loaded yet. wait until it is.
        if (!rest.currentBook) {
          return <Loader active />;
        }
        // a book is loaded
        if (!['author', 'collaborator'].includes(rest.currentBook.role)) {
          // the user is not the author or a collaborator
          return <Message warning>Forbidden</Message>;
        }
        // the user must be the author or a collaborator
        return <Component {...props} />;
      }
      // not authenticated. redirect to signin
      return (
        <Redirect
          to={{
            pathname: '/signin',
            search: `redirect=${encodeURIComponent(
              window.location.pathname + window.location.search
            )}`
          }}
        />
      );
    }}
  />
);

function mapStateToProps(state) {
  return {
    isAuthenticated:
      (!!firebase.auth().currentUser &&
        !firebase.auth().currentUser.isAnonymous) ||
      (!!localStorage.getItem('br-storage-key') &&
        !localStorage.getItem('br-is-anonymous')),
    idToken: localStorage.getItem('br-storage-key'),
    location: state.location,
    currentBook: state.book.currentBook
  };
}

export default withRouter(connect(mapStateToProps, null)(RestrictedBookRoute));
