import React, {useRef, useEffect} from 'react';
import {I18nextProvider} from 'react-i18next';
import {MediaContextProvider, createMediaStyle} from 'src/utils/Media';
import './App.css';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/sv';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/fi';
import 'moment/locale/is';
import 'moment/locale/de';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled, {ThemeProvider} from 'styled-components';
import {ToastContainer} from 'react-toastify';
import ReactGA from 'react-ga';
import {Helmet} from 'react-helmet';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';

import ContextMenu from './containers/menu/contextMenu';

import {
  loggedIn,
  updateIdToken,
  getUserProfile,
  loggedOut
} from './modules/user';

import {toggleAppSidebar, setIsEmbedded} from './modules/app';
import i18n from './utils/i18n/i18n';
import 'react-toastify/dist/ReactToastify.min.css';
import ApplicationContent from './AppContent';

import fire from './config/fire'; // app gets initialized

// utils
import {appUtil, authUtil, paddleTrackingUtil} from './utils';

import {getTheme} from './constants/theme';
import {AutoSaveProvider} from './hooks/useAutoSaveContext';
import usePreventBrowserSave from './hooks/usePreventBrowserSave';

const mediaStyles = createMediaStyle();

const AppWrapper = styled.div`
  height: 100vh;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'main';

  > header {
    grid-area: header;
  }
`;

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

// Create a client
const queryClient = new QueryClient();

const App = () => {
  usePreventBrowserSave();
  const dispatch = useDispatch();
  const history = useHistory();
  const idTokenRefreshTimer = useRef(null);

  const userProfile = useSelector(state => state.user.userProfile);

  useEffect(() => {
    // refresh user's id token to stay logged in
    const refreshIdToken = async () => {
      const idToken = await authUtil.getFreshIdToken();
      dispatch(updateIdToken(idToken));
    };
    window.name = process.env.REACT_APP_WINDOW_NAME;
    ReactGA.initialize('UA-101984305-1', {
      testMode: process.env.NODE_ENV !== 'production'
    }); //Unique Google Analytics tracking number
    // Paddle setups
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_PADDLE_ENV === 'sandbox'
    ) {
      // set sandbox env in dev
      window.Paddle.Environment.set('sandbox');
    }
    window.Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_ID),
      eventCallback: eventData => {
        paddleTrackingUtil.track(eventData);
      }
    });

    history.listen(() => {
      // close sidebar if changing location in small screen mode
      if (appUtil.isSmallScreen()) {
        dispatch(toggleAppSidebar(false));
      }
    });

    // check if we're embedden in an iframe
    if (inIframe()) {
      dispatch(setIsEmbedded(true));
    }

    fire.auth().onAuthStateChanged(user => {
      // this is only triggered on login/logout
      if (user) {
        user
          .getIdToken()
          .then(idToken => {
            window.localStorage.setItem('br-storage-key', user.uid);
            window.localStorage.setItem('br-is-anonymous', user.isAnonymous);
            dispatch(loggedIn(user, idToken));
            dispatch(getUserProfile(idToken));
            // refresh the id token every 5th minute
            clearInterval(idTokenRefreshTimer.current);
            idTokenRefreshTimer.current = setInterval(function () {
              refreshIdToken();
            }, 60 * 5 * 1000);
          })
          .catch(err => console.error(err));
      } else {
        window.localStorage.removeItem('br-storage-key');
        // stop any existing refresh token timer
        clearInterval(idTokenRefreshTimer.current);
        // logout
        dispatch(loggedOut());
        dispatch(toggleAppSidebar(false));
      }
    });

    fire.auth().onIdTokenChanged(user => {
      // this is triggered on all token events as well as login / logout
      if (user) {
        // User is signed in or token was refreshed.
        refreshIdToken();
      }
    });
  }, [dispatch, history]);

  useEffect(() => {
    const setupProfitWell = subscriptionId => {
      // if this is a paying user, setup profitwell with their paddle id
      if (subscriptionId) {
        profitwell('start', {user_id: subscriptionId}); // eslint-disable-line
      }
    };
    if (userProfile) {
      i18n.changeLanguage(userProfile.language);
      moment.locale(userProfile.language);
      swapTheme(userProfile.readerSettings);
      setupProfitWell(userProfile.account?.subscription.externalId);
    }
  }, [
    userProfile,
    userProfile?.language,
    userProfile?.readerSettings?.colorMode
  ]);

  const swapTheme = ({colorMode}) => {
    const colorSheet = document.querySelector('#color-theme');
    if (!colorSheet) {
      return;
    }
    colorSheet.setAttribute('href', `/styles/color-${colorMode}.css`);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <MediaContextProvider>
        <Helmet>
          <style>{mediaStyles}</style>
          <script
            async
            src='https://www.googletagmanager.com/gtag/js?id=AW-779841759'></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'AW-779841759', {
              'linker': {
                'accept_incoming': true
              }
            });
            `}
          </script>
          <script>
            {`
              !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
              },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
              a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
              // Insert Twitter Pixel ID and Standard Event data below
              twq('init','o0kcm');
              twq('track','PageView');
          `}
          </script>
          <script src='//platform.twitter.com/oct.js' type='text/javascript' />
          <script>
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '171805850082105');
                fbq('track', 'PageView');
            `}
          </script>
          <noscript>
            {`
              <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=171805850082105&ev=PageView&noscript=1"
              />
            `}
          </noscript>
        </Helmet>
        <ThemeProvider theme={getTheme(userProfile?.readerSettings?.colorMode)}>
          <I18nextProvider i18n={i18n}>
            <AutoSaveProvider>
              <AppWrapper>
                {!inIframe() && <ContextMenu />}
                <ApplicationContent />
              </AppWrapper>
              <ToastContainer
                autoClose={2500}
                hideProgressBar
                position='bottom-right'
                draggable
              />
            </AutoSaveProvider>
          </I18nextProvider>
        </ThemeProvider>
      </MediaContextProvider>
    </QueryClientProvider>
  );
};

export default App;
