import React, {useState, useEffect, useMemo} from 'react';
import {withRouter} from 'react-router-dom';
import {
  Button,
  Icon,
  Dropdown,
  Table,
  Modal,
  Label,
  Tab,
  Menu
} from 'semantic-ui-react';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import {Media} from 'src/utils/Media';

import InvitationForm from './invitationForm';
import BulkInvitationForm from './bulkInvitationForm';
import ReadingNotifications from './reading-notifications';
import JoinBetaFormDesigner from './signup-form/joinBetaFormDesigner';
import ProductLimitMessage from '../../components/standard-messages/productLimitMessage';
import PublicUserProfile from '../user/publicUserProfile';
import BroadcastMessage from './bulk-actions/broadcastMessage';
import ReaderEntryTableRow from './readerEntryTableRow';
import {EllipsisSpan, NoWrapLabel, PaddedWrapper} from '../../style';
import {useParams, useHistory} from 'react-router';

// UX tracking
import {
  authUtil,
  uxAnalyticsUtil,
  copyToClipBoardUtil,
  productCapUtil,
  accessUtil,
  featureToggleUtil
} from '../../utils';

import {
  updateReader,
  deleteReader,
  fetchReaders,
  fetchJoinRequests,
  sortReaderList,
  toggleReaderSelection,
  sendInvitation,
  approveJoinRequest,
  declineJoinRequest,
  sendSurveyReminder
} from '../../modules/reader';
import {DeviceMinWidths} from '../../constants/size';
import {render} from '@testing-library/react';

const download = require('downloadjs');

const ReaderList = ({}) => {
  // globals
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const {bookId} = useParams();

  // redux state
  const readers = useSelector(state => state.reader.readers) ?? [];
  const currentBook = useSelector(state => state.book.currentBook);
  const joinRequests = useSelector(state => state.reader.joinRequests);
  const userProfile = useSelector(state => state.user.userProfile);
  const activeAccount = useSelector(state => state.account.activeAccount);
  const sorting = useSelector(state => state.reader.sorting);

  // local state
  const [isLoading, setIsLoading] = useState(false);
  const [menuActiveIndex, setMenuActiveIndex] = useState(0);
  const [localReaders, setLocalReaders] = useState([]);
  const [showInvitationForm, setShowInvitationForm] = useState<
    'single' | 'bulk' | false
  >(false);
  const [joinRequestToBeConfirmed, setJoinRequestToBeConfirmed] =
    useState(null);
  const [showReaderProfile, setShowReaderProfile] = useState(false);
  const [currentTab, setCurrentTab] = useState('readers');

  // functions
  useEffect(() => {
    const setup = async () => {
      uxAnalyticsUtil.trackPageView(history.location.pathname);
      const idToken = await authUtil.getFreshIdToken();
      dispatch(fetchReaders(idToken, bookId, []));
      dispatch(fetchJoinRequests(idToken, bookId));
    };
    setup();
  }, [bookId, dispatch]);

  const invitationSent = data => {
    setLocalReaders(prev => {
      const newReaders = [...prev];
      newReaders.push(data);
      return newReaders;
    });
  };

  const invitationRemoved = id => {
    setLocalReaders(prev => {
      const newReaders = [...prev];
      const index = newReaders.findIndex(reader => reader.id === id);
      if (index > -1) {
        newReaders.splice(index, 1);
      }
      return newReaders;
    });
  };

  const handleSort = event => {
    const {sortColumn, sortDirection} = event.currentTarget.dataset;
    dispatch(
      sortReaderList(
        sortColumn,
        sortDirection === 'descending' ? 'ascending' : 'descending'
      )
    );
  };

  const handleDeleteReaderClick = async (readerId: string) => {
    const idToken = await authUtil.getFreshIdToken();
    const reader = readers.find(r => r._id === readerId);
    if (
      reader &&
      ['SENT', 'CREATED', 'APPROVED', 'DECLINED'].includes(
        reader.invitation.status
      )
    ) {
      dispatch(deleteReader(idToken, reader));
    }
  };

  const handleMessageUserClick = (readerId: string) => {
    const reader = readers.find(entry => entry._id === readerId);
    if (reader && reader.user) {
      history.push(`/inbox/conversations/${reader.user._id}`);
    }
  };

  const handleGoToProfileClick = (readerId: string) => {
    const reader = readers.find(entry => entry._id === readerId);
    if (reader && reader.user) {
      history.push(`/u/${reader.user._id}`);
    }
  };

  const removeInvitation = async id => {
    const idToken = await authUtil.getFreshIdToken();

    fetch(
      `${process.env.REACT_APP_API_HOST}/api/books/${currentBook._id}/invitation/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => res.json())
      .then(() => {
        invitationRemoved(id);
      })
      .catch(err => {
        toast.error('SomethingWentWrong');
        console.log('err:', err);
      });
  };

  const handleToggleReaderEnabledClick = async (readerId: string) => {
    const idToken = await authUtil.getFreshIdToken();
    const reader = readers.find(reader => {
      return reader._id === readerId;
    });
    if (reader) {
      // reader.enabled = !reader.enabled;
      const updateData = {enabled: !reader.enabled};
      dispatch(updateReader(idToken, currentBook._id, reader._id, updateData));
    }
  };

  const doSendInvitation = async event => {
    event.stopPropagation();
    event.preventDefault();
    const idToken = await authUtil.getFreshIdToken();
    const {readerId} = event.currentTarget.dataset;
    const readerEntry = readers.find(reader => reader._id === readerId);
    if (readerEntry) {
      dispatch(sendInvitation(idToken, readerEntry));
    }
  };

  const getOpportunityUrl = (bookId, invitationToken) => {
    const gaTracking =
      'utm_source=offline-communication&utm_medium=get-invitation-link&utm_campaign=get-invitation-link';
    const actionUrl = `${process.env.REACT_APP_WEB_HOST}/discover/${bookId}?token=${invitationToken}&${gaTracking}`;
    toast.info(t('CopiedXToClipboard', {x: actionUrl}));
    return actionUrl;
  };

  const copyURLToClipBoard = (token: string) => {
    const url = getOpportunityUrl(currentBook._id, token);
    copyToClipBoardUtil.copyItemToClipBoard(url);
  };

  const downloadReaderList = async () => {
    const idToken = await authUtil.getFreshIdToken();
    fetch(
      `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}/books/${currentBook._id}/readers?format=csv`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'br-token': idToken
        }
      }
    )
      .then(res => res.blob())
      .then(file => {
        download(file, 'readers.csv', 'text/csv');
        uxAnalyticsUtil.trackEvent({
          category: 'Book Management',
          action: 'downloaded-reader-list'
        });
      })
      .catch(err => {
        console.log('err:', err);
      });
  };

  const doApproveJoinRequest = (joinRequest, contentVersion) => {
    if (joinRequest) {
      setJoinRequestToBeConfirmed({
        joinRequest,
        contentVersion,
        swap: joinRequest.swap
      });
    }
  };

  const confirmApproval = async () => {
    if (!!joinRequestToBeConfirmed) {
      console.log('confirmApproval:', joinRequestToBeConfirmed);
      // reset state and approve request
      const idToken = await authUtil.getFreshIdToken();
      const {joinRequest, contentVersion} = joinRequestToBeConfirmed;
      dispatch(approveJoinRequest(idToken, joinRequest, {contentVersion}));
      setJoinRequestToBeConfirmed(null);
    }
  };

  useEffect(() => {
    // if we have a join request to be confirmed with no swap, confirm immediately
    if (!!joinRequestToBeConfirmed && !joinRequestToBeConfirmed.swap) {
      confirmApproval();
    }
  }, [joinRequestToBeConfirmed]);

  const cancelApproval = () => {
    setJoinRequestToBeConfirmed(null);
  };

  const doDeclineJoinRequest = async (readerId: string) => {
    const idToken = await authUtil.getFreshIdToken();
    const joinRequest = joinRequests.find(
      joinRequest => joinRequest._id === readerId
    );
    if (joinRequest) {
      dispatch(declineJoinRequest(idToken, joinRequest));
    }
  };

  const doSendSurveyReminder = async (joinRequest: any) => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(sendSurveyReminder(idToken, joinRequest));
  };

  const doShowReaderProfile = (userId?: string) => {
    if (userId) {
      setShowReaderProfile(userId);
    }
  };

  const doCloseReaderProfile = () => {
    setShowReaderProfile(false);
  };

  const onReaderSelectionChange = (readerId: string) => {
    dispatch(toggleReaderSelection(readerId));
  };

  const renderReaderTable = readerInvitationStatus => {
    const hasReaders = readers && readers.length > 0;
    const hasSelectedReaders =
      readers && readers.some(reader => reader.selected);
    const canAddMoreReaders = productCapUtil.canAddMoreReaders({
      activeAccount,
      readers,
      currentBook
    });
    // filter the list based
    const filteredReaderList = readers.filter(reader =>
      readerInvitationStatus.includes(reader.invitation.status)
    );
    const contentVersionOptions = currentBook.content.map((version, index) => ({
      key: version._id,
      value: version._id,
      text:
        index === currentBook.content.length - 1
          ? `v${
              Number.isInteger(version.versionNumber)
                ? version.versionNumber
                : index + 1
            } (${t('latestVersion')})`
          : `v${
              Number.isInteger(version.versionNumber)
                ? version.versionNumber
                : index + 1
            }`
    }));
    const x = joinRequestToBeConfirmed?.swap?.title;
    const canBatchEdit = featureToggleUtil.hasFeatureAccess(
      userProfile,
      'broadcast'
    );

    return (
      <>
        {!!joinRequestToBeConfirmed && !!joinRequestToBeConfirmed.swap && (
          <Modal
            closeOnDocumentClick
            size='mini'
            open={!!joinRequestToBeConfirmed}
            onClose={cancelApproval}>
            <Modal.Header>{t('Confirm')}</Modal.Header>
            <Modal.Content>
              <Trans i18nKey='ThisIsASwapRequestForX'>
                <p>
                  This is a swap request for{' '}
                  <a
                    href={`/discover/${joinRequestToBeConfirmed.swap._id}`}
                    target='betareader.io_reader'>
                    {{x}}
                  </a>
                  . If you approve this request, you will be added as a reader
                  for {{x}}.
                </p>
              </Trans>
              <em style={{fontSize: '0.8em'}}>{t('ThisIsABetaFeature')}</em>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={cancelApproval}>
                {t('Cancel') as string}
              </Button>
              <Button
                positive
                icon='checkmark'
                labelPosition='right'
                content={t('Accept') as string}
                onClick={confirmApproval}
              />
            </Modal.Actions>
          </Modal>
        )}
        {hasSelectedReaders &&
          featureToggleUtil.hasFeatureAccess(userProfile, 'broadcast') && (
            <div>
              <BroadcastMessage />
            </div>
          )}
        <Table sortable celled selectable compact unstackable>
          {hasReaders && (
            <Table.Header>
              <Table.Row>
                {canBatchEdit && <Table.HeaderCell />}
                <Table.HeaderCell />
                <Media greaterThanOrEqual='mobile'>
                  {(className, renderChildren) =>
                    renderChildren && (
                      <Table.HeaderCell
                        sorted={
                          sorting.column === 'name' ? sorting.direction : null
                        }
                        data-sort-direction={
                          sorting.column === 'name' ? sorting.direction : null
                        }
                        data-sort-column='name'
                        onClick={handleSort}>
                        {readerInvitationStatus.includes(
                          'ACCEPTED' || 'APPROVED'
                        ) && t('Readers')}
                        {readerInvitationStatus.includes('SENT' || 'CREATED') &&
                          t('PendingReadInvitations')}
                        {readerInvitationStatus.includes('REQUESTED') &&
                          t('PendingReadRequests')}
                      </Table.HeaderCell>
                    )
                  }
                </Media>
                <Media greaterThanOrEqual='mobile'>
                  {(className, renderChildren) =>
                    renderChildren && (
                      <Table.HeaderCell
                        sorted={
                          sorting.column === 'createdAt'
                            ? sorting.direction
                            : null
                        }
                        data-sort-direction={
                          sorting.column === 'createdAt'
                            ? sorting.direction
                            : null
                        }
                        data-sort-column='createdAt'
                        onClick={handleSort}>
                        {t('Created')}
                      </Table.HeaderCell>
                    )
                  }
                </Media>
                <Media
                  greaterThanOrEqual='mobile'
                  // greaterThan='mobile'
                >
                  {(className, renderChildren) =>
                    renderChildren && (
                      <Table.HeaderCell
                        sorted={
                          sorting.column === 'contentVersionNumber'
                            ? sorting.direction
                            : null
                        }
                        data-sort-direction={
                          sorting.column === 'contentVersionNumber'
                            ? sorting.direction
                            : null
                        }
                        data-sort-column='contentVersionNumber'
                        onClick={handleSort}>
                        {t('Version')}
                      </Table.HeaderCell>
                    )
                  }
                </Media>
                {readerInvitationStatus.includes('ACCEPTED' || 'APPROVED') && (
                  <Media
                    greaterThanOrEqual='mobile'
                    // greaterThan='mobile'
                  >
                    {(className, renderChildren) =>
                      renderChildren && (
                        <Table.HeaderCell
                          sorted={
                            sorting.column === 'firstSeenAt'
                              ? sorting.direction
                              : null
                          }
                          data-sort-direction={
                            sorting.column === 'firstSeenAt'
                              ? sorting.direction
                              : null
                          }
                          data-sort-column='firstSeenAt'
                          onClick={handleSort}>
                          {t('StartedReading')}
                        </Table.HeaderCell>
                      )
                    }
                  </Media>
                )}
                {readerInvitationStatus.includes('ACCEPTED' || 'APPROVED') && (
                  <Table.HeaderCell
                    sorted={
                      sorting.column === 'lastSeenAt' ? sorting.direction : null
                    }
                    data-sort-direction={
                      sorting.column === 'lastSeenAt' ? sorting.direction : null
                    }
                    data-sort-column='lastSeenAt'
                    onClick={handleSort}>
                    {t('LastSeenAt')}
                  </Table.HeaderCell>
                )}
                {readerInvitationStatus.includes('ACCEPTED' || 'APPROVED') && (
                  <Table.HeaderCell
                    sorted={
                      sorting.column === 'partIndex' ? sorting.direction : null
                    }
                    data-sort-direction={
                      sorting.column === 'partIndex' ? sorting.direction : null
                    }
                    data-sort-column='partIndex'
                    onClick={handleSort}>
                    {t('CurrentPosition')}
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell width={1} textAlign='center'>
                  <Icon name='settings' />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {filteredReaderList.map(reader => (
              <ReaderEntryTableRow
                key={reader._id}
                reader={reader}
                isEditable={accessUtil.isAllowed(currentBook.access, ['edit'])}
                contentVersionOptions={contentVersionOptions}
                canAddMoreReaders={canAddMoreReaders}
                isSelectable={canBatchEdit}
                onDelete={handleDeleteReaderClick}
                onMessage={handleMessageUserClick}
                onGoToProfile={handleGoToProfileClick}
                onToggleEnabled={handleToggleReaderEnabledClick}
                onShowReaderProfile={doShowReaderProfile}
                onSelectedChange={onReaderSelectionChange}
                onCopyURLToClipBoard={copyURLToClipBoard}
                onApproveJoinRequest={doApproveJoinRequest}
                onDeclineJoinRequest={doDeclineJoinRequest}
                onSendInvitation={doSendInvitation}
                onUpdateReader={async (book, readerId, data) => {
                  const idToken = await authUtil.getFreshIdToken();
                  console.log({
                    idToken,
                    book,
                    readerId,
                    data
                  });
                  dispatch(updateReader(idToken, book, readerId, data));
                }}
              />
            ))}
          </Table.Body>
        </Table>
      </>
    );
  };

  const hasReaders = readers && readers.length > 0;
  const totalActiveReaders = useMemo(
    () =>
      readers.filter(
        reader =>
          ['APPROVED', 'ACCEPTED', 'ABANDONED'].includes(
            reader.invitation.status
          ) && reader.enabled
      ).length,
    [readers]
  );

  const totalPendingInvitations = useMemo(
    () =>
      readers.filter(reader =>
        ['SENT', 'CREATED'].includes(reader.invitation.status)
      ).length,
    [readers]
  );

  const totalPendingRequests = useMemo(
    () =>
      readers.filter(reader => reader.invitation.status === 'REQUESTED').length,
    [readers]
  );

  const canAddMoreReaders = productCapUtil.canAddMoreReaders({
    activeAccount,
    readers,
    currentBook
  });

  let maxReadersPerBook = activeAccount.maxReadersPerBook;
  if (currentBook.maxReadersPerBookOverride !== undefined) {
    // override if applicable
    maxReadersPerBook = currentBook.maxReadersPerBookOverride;
  }
  const showProductLimitMessage =
    !canAddMoreReaders &&
    currentBook.privacy !== 'unpublished' &&
    currentBook.role === 'author';
  let inActiveReaders = [];
  let nonStarters = [];
  if (hasReaders) {
    inActiveReaders = readers.filter(
      reader =>
        reader.enabled &&
        reader.isInactive &&
        reader.invitation.status === 'ACCEPTED'
    );
    nonStarters = readers.filter(
      reader =>
        reader.enabled &&
        reader.isInactive &&
        !['ACCEPTED', 'DECLINED', 'ABANDONED'].includes(reader.invitation.status)
    );
  }
  const panes = [
    {
      menuItem: (
        <Menu.Item
          key='Readers'
          onClick={() => {
            setMenuActiveIndex(0);
            setCurrentTab(t('activeReaders'));
          }}
          style={{
            minWidth: '5%',
            width: '20%'
          }}>
          <Icon name='users' />
          <Media greaterThanOrEqual='tablet'>
            {(className, renderChildren) =>
              renderChildren && (
                <EllipsisSpan>{t('activeReaders')}</EllipsisSpan>
              )
            }
          </Media>
          <NoWrapLabel
            size='mini'
            circular
            color={totalActiveReaders > 0 ? 'green' : 'grey'}>
            {activeAccount && `${totalActiveReaders}`}
          </NoWrapLabel>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane as='div'>
          {renderReaderTable(['APPROVED', 'ACCEPTED', 'ABANDONED'])}
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        <Menu.Item
          key='SENT'
          onClick={() => {
            setMenuActiveIndex(1);
            setCurrentTab(t('PendingReadInvitations'));
          }}
          style={{
            minWidth: '5%',
            width: '20%'
          }}>
          <Icon name='send' />
          <Media greaterThanOrEqual='tablet'>
            {(className, renderChildren) =>
              renderChildren && (
                <EllipsisSpan>{t('PendingReadInvitations')}</EllipsisSpan>
              )
            }
          </Media>
          <Label
            size='mini'
            circular
            color={totalPendingInvitations > 0 ? 'orange' : 'grey'}>
            {totalPendingInvitations}
          </Label>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane as='div'>{renderReaderTable(['SENT', 'CREATED'])}</Tab.Pane>
      )
    },
    {
      menuItem: (
        <Menu.Item
          key='REQUESTED'
          onClick={() => {
            setMenuActiveIndex(2);
            setCurrentTab(t('PendingReadRequests'));
          }}
          style={{
            minWidth: '5%',
            width: '20%'
          }}>
          <Icon name='inbox' />
          <Media greaterThanOrEqual='tablet'>
            {(className, renderChildren) =>
              renderChildren && (
                <EllipsisSpan>{t('PendingReadRequests')}</EllipsisSpan>
              )
            }
          </Media>
          <Label
            size='mini'
            circular
            color={totalPendingRequests > 0 ? 'orange' : 'grey'}>
            {totalPendingRequests}
          </Label>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane as='div'>{renderReaderTable(['REQUESTED'])}</Tab.Pane>
      )
    },
    {
      menuItem: (
        <React.Fragment key='buttons'>
          <Menu.Item
            style={{marginLeft: 'auto'}}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <Dropdown
              icon='ellipsis vertical'
              basic
              style={{color: 'grey', marginLeft: 'auto'}}
              pointing='right'>
              <Dropdown.Menu>
                <Dropdown.Item
                  key='downloadDropdown'
                  onClick={downloadReaderList}
                  content={
                    <span>
                      <>
                        <Icon name='download' />
                        {t('DownloadReaderList')}
                      </>
                    </span>
                  }
                />
                <Dropdown.Item
                  key='addReaderDropdown'
                  onClick={() => setShowInvitationForm('single')}
                  content={
                    <span>
                      <Icon name='add user' />
                      {currentBook.privacy === 'unpublished'
                        ? t('ChangePublishingStatusToAddReaders')
                        : t('AddReader')}
                    </span>
                  }
                />
                <Dropdown.Item
                  key='addReadersDropdown'
                  disabled={
                    !accessUtil.isAllowed(currentBook?.access, ['edit']) ||
                    productCapUtil.isOnFreePlan({userProfile})
                  }
                  onClick={() => setShowInvitationForm('bulk')}
                  content={
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <span>
                        <Icon name='users' />
                        {`${t('BulkAddReaders')} (BETA)`}
                      </span>
                      {productCapUtil.isOnFreePlan({userProfile}) && (
                        <div>
                          <em>{t('UpgradeToUnlock') as string}</em>
                        </div>
                      )}
                    </div>
                  }
                />
                <JoinBetaFormDesigner
                  key='joinRequestDropdown'
                  floated='right'
                  size='small'
                  trigger='Dropdown.Item'
                  asModal
                  bookTitle={currentBook.title}
                  questions={
                    currentBook.readerSignupSurvey
                      ? currentBook.readerSignupSurvey.questions
                      : []
                  }
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </React.Fragment>
      )
    }
  ];
  return (
    <PaddedWrapper>
      <ReadingNotifications
        access={currentBook.access}
        inActiveReaders={inActiveReaders}
        nonStarters={nonStarters}
      />
      {userProfile && !canAddMoreReaders && showProductLimitMessage && (
        <ProductLimitMessage reachedCap='maxReaders' />
      )}
      <JoinBetaFormDesigner
        floated='right'
        asModal
        trigger='Button'
        size='small'
        bookTitle={currentBook.title}
        questions={
          currentBook.readerSignupSurvey
            ? currentBook.readerSignupSurvey.questions
            : []
        }
        readOnly={!accessUtil.isAllowed(currentBook.access, ['edit'])}
      />

      <Button
        icon
        labelPosition='left'
        style={{marginRight: 10, marginLeft: 10}}
        primary
        size='small'
        onClick={() => setShowInvitationForm('single')}>
        <Icon name='add user' />
        {t('AddReader')}
      </Button>
      <Tab
        activeIndex={menuActiveIndex}
        menu={{
          key: 'menu',
          secondary: true,
          pointing: true,
          style: {display: 'flex', width: '100%'}
        }}
        panes={panes}
      />
      <Modal
        size='tiny'
        open={showInvitationForm === 'single'}
        closeIcon
        onClose={() => setShowInvitationForm(false)}>
        <Modal.Header>
          <Icon name='add user' />
          {t('AddReader')}
        </Modal.Header>
        <Modal.Content>
          <InvitationForm invitationSent={() => setShowInvitationForm(false)} />
        </Modal.Content>
      </Modal>
      <Modal
        // size='tiny'
        open={showInvitationForm === 'bulk'}
        closeIcon
        onClose={() => setShowInvitationForm(false)}>
        <Modal.Header>
          <Icon name='add user' />
          {t('BulkAddReaders')}
        </Modal.Header>
        <Modal.Content>
          <BulkInvitationForm
            invitationSent={() => setShowInvitationForm(false)}
          />
        </Modal.Content>
      </Modal>
      {showReaderProfile && (
        <Modal size='small' open closeIcon onClose={doCloseReaderProfile}>
          <Modal.Content>
            <PublicUserProfile userId={showReaderProfile} />
          </Modal.Content>
        </Modal>
      )}
    </PaddedWrapper>
  );
};

export default ReaderList;
