import React, { Component } from 'react';

// redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useHistory,
} from 'react-router-dom';

import styled, { css } from 'styled-components';

// containers
import AcceptCollaboratorInvitation from './containers/acceptCollaboratorInvitation';
import About from './containers/home/about';
import Account from './containers/user/index';
import Action from './containers/user/action';
import AppAlerts from './containers/app-alerts';
import AccountSelector from './containers/user/accountMembers/accountSelector';
import Books from './containers/book';
import Discover from './containers/discover';
import ExitSurvey from './containers/exitSurvey/exitSurvey';
import Feed from './containers/feed';
import Footer from './containers/home/footer';
import LandingPage from './containers/home/landingPage';
import MyManuscripts from './containers/home/mymanuscripts';
import ManuscriptInbox from './containers/manuscriptInbox';
import NotFound from './components/notFound';
import MessageInbox from './containers/message';
import NewUser from './containers/user/newUser';
import Notifications from './containers/notifications';
import NPSSurvey from './containers/nps/npsSurvey';
import Products from './containers/billing/subscriptions/products';
import PrivacyPolicy from './containers/home/privacyPolicy';
import PublicUserProfile from './containers/user/publicUserProfile';
import Readathon from './containers/readathon';
import Reader from './containers/content/reader';
import ReadingList from './containers/readingList';
import ReaderIndex from './containers/readerIndex';
import ResetPassword from './containers/user/resetPassword';
import Review from './containers/content/reader/review/review';
import SignIn from './containers/user/signin';
import SignUp from './containers/user/signup';
import TermsOfService from './containers/home/tos';
import Actions from './containers/actions';
import GoodbyePage from './containers/goodbye';

import Restricted from './restricted';

import UserProfileBuilder from './containers/user/userProfileBuilder';

import { MediaQuery } from './constants/size';
import Sidebar from './containers/menu/Sidebar';

const MainWrapper = styled.main`
  grid-area: main;
  overflow: auto;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  ${MediaQuery.tablet} {
    grid-template-columns: minmax(auto, 250px) 1fr;
    grid-template-areas: 'sidebar content';
  }
`;

const MainContentWrapper = styled.div`
  ${MediaQuery.tablet} {
    ${({ sidebarVisible }) =>
      sidebarVisible
        ? css`
            grid-area: sidebar-start / sidebar-end / content-end / content-end;
          `
        : css`
            grid-area: sidebar-start / sidebar-start / content-end / content-end;
          `}
  }
  padding: 15px 0px 15px 0px;
`;

const AppContent = ({
  sidebarVisible,
  userProfile,
  isEmbedded,
  childProps,
}) => {
  const history = useHistory();
  const activeItem = history.location.pathname;
  const showMenu = !['/signup', '/signin', '/action', '/nps'].includes(
    activeItem
  );
  const showFooter = ['/signup', '/signin'].includes(activeItem);
  return (
    <>
      {showMenu && [
        <AppAlerts key='app-alerts' />,
        userProfile && <AccountSelector key='account-selector' />,
      ]}
      <UserProfileBuilder />

      <MainWrapper id='main' sidebarVisible={sidebarVisible}>
        <Sidebar sidebarVisible={sidebarVisible} />
        {/* TODO: see if we can remove the marginTop as it creates a strange look on book content top */}
        <MainContentWrapper sidebarVisible={sidebarVisible}>
          <Switch>
            <Restricted exact path='/read' component={Reader} />
            <Restricted exact path='/readinglist' component={ReadingList} />
            <Restricted path='/readers' component={ReaderIndex} />
            <Restricted exact path='/review' component={Review} />
            <Restricted path='/notifications' component={Notifications} />
            <Restricted path='/inbox' component={MessageInbox} />
            <Restricted exact path='/mymanuscripts' component={MyManuscripts} />
            <Restricted exact path='/newUser' component={NewUser} />
            <Restricted path='/account/:subPage' component={Account} />
            <Restricted path='/u/:userId' component={PublicUserProfile} />
            <Restricted
              exact
              path='/accept-invitation'
              component={AcceptCollaboratorInvitation}
            />
            <Restricted exact path='/exit-survey' component={ExitSurvey} />
            <Restricted exact path='/feed' component={Feed} />
            <Restricted path='/manuscript-inbox' component={ManuscriptInbox} />
            <Route exact path='/pricing' component={Products} />
            <Route exact path='/products' component={Products} />
            <Route path='/link-action' component={Actions} />
            <Route path='/books' component={Books} />
            <Route
              path='/readerindex'
              render={() => <Redirect to='/readers' />}
            />
            <Route path='/readathon' component={Readathon} />
            <Route path='/discover' component={Discover} />
            <Route
              path='/profile'
              render={() => <Redirect to='/account/profile' />}
            />
            <Route path='/nps' component={NPSSurvey} />
            <Route path='/reset-password' component={ResetPassword} />
            <Route path='/signin' component={SignIn} />
            <Route path='/signup' component={SignUp} />
            <Route path='/goodbye' component={GoodbyePage} />
            <Route exact path='/about' component={About} />
            <Route exact path='/tos' component={TermsOfService} />
            <Route exact path='/privacy' component={PrivacyPolicy} />
            <Route exact path='/action' component={Action} />
            <Route exact path='/' render={() => <Redirect to='/discover' />} />
            <Route
              exact
              path='/index.html'
              component={LandingPage}
              props={childProps}
            />
            {/* Finally, catch all unmatched routes */}
            <Route component={NotFound} />
          </Switch>
        </MainContentWrapper>
        {showFooter && isEmbedded && <Footer />}
      </MainWrapper>
    </>
  );
};

// redux stuff
const mapStateToProps = (state) => ({
  isEmbedded: state.app.isEmbedded,
  sidebarVisible: state.app.sidebarVisible,
  userProfile: state.user.userProfile,
  activeAccount: state.account.activeAccount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppContent)
);
