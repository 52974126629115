import React from 'react';
import {Icon} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import gaTracker from 'src/utils/gaTracker';

// stateless component
const UpgradeToUnlock = () => {
  const {t} = useTranslation();
  return (
    <Link
      to={'/products'}
      style={{color: 'orange'}}
      onClick={() => {
        gaTracker.trackEvent({
          category: 'Upgrade',
          action: 'ClickedUpgradeToUnlock'
        });
      }}>
      <em>
        {t('UpgradeToUnlock')} <Icon name='lock' />
      </em>
    </Link>
  );
};
export default UpgradeToUnlock;
