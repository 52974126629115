import React, {PureComponent} from 'react';

import '../../App.css';

// i18n
import {withTranslation} from 'react-i18next';

// Routes
import {withRouter} from 'react-router-dom';

// redux
import {push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Components
import styled from 'styled-components';
import {Icon, Dropdown} from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import {colorModes, loggedOut, updateUserProfile} from '../../modules/user';

import {resetActiveAccount} from '../../modules/account';

import Avatar from '../../components/avatar/avatar';

import {authUtil, productCapUtil} from '../../utils';
import SupportModal from '../../components/menu/supportModal';

const UserDropdownWrapper = styled(Dropdown)`
  > i.dropdown.icon {
    margin: 0px 0px 0px 0px !important;
  }
`;

export class UserDropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {showSupportModal: false};
  }

  handleSignout = () => {
    const {userProfile} = this.props;
    if (userProfile.accountMembership.length > 1) {
      // if this user has several accounts, just disable the active one
      // so they can choose to switch account instead of signing out completely
      this.props.resetActiveAccount();
    } else {
      this.props.resetActiveAccount();
      this.props.loggedOut();
      firebase.auth().signOut();
    }
  };

  handleSignIn = () => {
    this.props.changePage('/signin');
  };

  openSupportConversation = () => {
    this.setState({showSupportModal: false});
    this.props.changePage('/inbox/conversations/support');
  };

  toggleSupportModal = () => {
    const {showSupportModal} = this.state;
    this.setState({showSupportModal: !showSupportModal});
  };

  toggleColorMode = async () => {
    const {userProfile} = this.props;
    if (!userProfile) {
      return;
    }
    const currentIndex = colorModes.findIndex(
      mode => mode === userProfile.readerSettings.colorMode
    );
    const nextIndex = (currentIndex + 1) % colorModes.length;
    const nextColorMode = colorModes[nextIndex];
    const idToken = await authUtil.getFreshIdToken();
    this.props.updateUserProfile(idToken, {
      readerSettings: {
        ...userProfile.readerSettings,
        colorMode: nextColorMode
      }
    });
  };

  render() {
    const {activeAccount, userProfile, t} = this.props;
    const {showSupportModal} = this.state;
    const canEditAccount = productCapUtil.canEditAccount({
      activeAccount,
      userProfile
    });
    return [
      <SupportModal
        key='SupportModal'
        onClose={this.toggleSupportModal}
        open={showSupportModal}
        onStartSupportChat={this.openSupportConversation}
        signedIn={!!userProfile}
      />,
      <UserDropdownWrapper
        key='dropdown'
        item
        style={{height: 40}}
        trigger={
          userProfile ? (
            <Avatar
              name={userProfile && userProfile.displayName}
              src={!userProfile ? '/assets/images/BR-square.png' : null}
            />
          ) : (
            t('SignIn')
          )
        }>
        <Dropdown.Menu>
          {userProfile && [
            <Dropdown.Header key='user-header' content={t('UserInfo')} />,
            <Dropdown.Item
              key='profile'
              content={t('Profile')}
              icon='user'
              onClick={() => this.props.changePage('/account/profile')}
            />,
            <Dropdown.Item
              key='settings'
              content={t('Settings')}
              icon='settings'
              onClick={() => this.props.changePage('/account/settings')}
            />,
            <Dropdown.Item
              key='color-mode'
              icon='lightbulb'
              content={t('ColorMode')}
              onClick={this.toggleColorMode}
            />,
            canEditAccount && (
              <Dropdown.Header key='account-header' content={t('Account')} />
            ),
            canEditAccount && (
              <Dropdown.Item
                key='members'
                content={t('Members')}
                icon='users'
                onClick={() => this.props.changePage('/account/members')}
              />
            ),
            canEditAccount && (
              <Dropdown.Item
                key='subscription'
                content={t('Subscription')}
                icon='payment'
                onClick={() => this.props.changePage('/account/subscription')}
              />
            ),
            <Dropdown.Divider key='account-divider' />,
            // Only show reader bonus option if relevant
            userProfile.maxReadersPerBook > -1 && (
              <Dropdown.Item
                key='reader-bonus'
                content={t('GetMoreReaders')}
                icon='certificate'
                onClick={() => this.props.changePage('/account/reader-bonus')}
              />
            )
          ]}
          <Dropdown.Item
            as='a'
            key='supportContextMenu'
            onClick={this.toggleSupportModal}>
            <Icon name='help circle' />
            <span>{t('Support')}</span>
          </Dropdown.Item>
          <Dropdown.Item
            key='sign-in-or-out'
            onClick={userProfile ? this.handleSignout : this.handleSignIn}
            style={{alignSelf: 'flex-end'}}
            content={[
              <Icon key='icon' name={userProfile ? 'log out' : 'sign-in'} />,
              <span key='text' style={{marginLeft: 5}}>
                {userProfile
                  ? userProfile.accountMembership.length > 1
                    ? t('SwitchAccount')
                    : t('SignOut')
                  : t('SignIn')}
              </span>
            ]}
          />
        </Dropdown.Menu>
      </UserDropdownWrapper>
    ];
  }
}

// redux stuff
const mapStateToProps = state => ({
  activeAccount: state.account.activeAccount,
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  fontSizes: state.app.fontSizes
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loggedOut,
      resetActiveAccount,
      updateUserProfile,
      changePage: newPage => push(newPage)
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDropdown))
);
